<template>
    <div class="headandsubtext">
        <h1>{{heading}}</h1>
        <h2>{{heading2}}</h2>
        <p class="sub">{{subHeading}}</p>
        <p class="para">{{subHeading2}}</p>
        <p class="centerPara">{{subHeading3}}</p>
    </div>
</template>

<script>
export default {
    name: 'HeadAndSubTexts',
    components: {

    },
    props: {
        heading: {
            type: String
        },
        heading2: {
            type: String
        },
        subHeading: {
            type: String
        },
        subHeading2: {
            type: String
        },
        subHeading3: {
            type: String
        }
    },
}
</script>

<style lang="scss" scoped>
@import './HeadAndSubTexts.scss';
</style>