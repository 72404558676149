<template>
    <div class="carousel-wrapper">
        <VueSlickCarousel v-bind="settings">
            <slot></slot>
            <template #prevArrow>
                <div class="prevArrow">
                    <img
                        class="left-arrow"
                        src="@/assets/images/left-arrow.svg"
                        alt=""
                    />
                </div>
            </template>

            <template #nextArrow>
                <div class="nextArrow">
                    <img
                        class="right-arrow"
                        src="@/assets/images/right-arrow.svg"
                        alt=""
                    />
                </div>
            </template>
        </VueSlickCarousel>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
    name: 'Carousel',
    components: {
        VueSlickCarousel,
    },
    props: {
        settings: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@import './Carousel.scss';
</style>
