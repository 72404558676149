<template>
    <div class="card-wrapper">
        <div class="card">
            <div v-if="unavailable == true" class="card-overlay">
                <p class="text-unavailable">Product Unavailable</p>
            </div>
            <div
                class="available-3d"
                v-if="threeDavailable == true && available == true"
            >
                3D model available
            </div>
            <div class="card-image-wrapper">
                <img
                    :src="img"
                    :class="{ forServices: forServices == true }"
                    :alt="cardname"
                />
                <div class="card-quantity" v-if="quantity > 1">
                    {{ quantity }}
                </div>
            </div>
            <div v-if="forServices == true">
                <div
                    class="service-content"
                    :class="{ unavailable: unavailable == true }"
                >
                    <p class="card-text-1">
                        {{ cardname }}
                    </p>
                    <p
                        class="card-text-2"
                        v-if="price"
                        :class="requiredDomain == false ? 'blur' : ''"
                    >
                        {{ price }}
                    </p>
                </div>
            </div>
            <div
                v-else
                class="card-content"
                :class="{ unavailable: unavailable == true }"
            >
                <p
                    class="card-text-1"
                    :class="{
                        'card-name': !price,
                        'product-style': productstyle,
                    }"
                >
                    {{ cardname }}
                </p>
                <p
                    class="card-text-2"
                    :class="{
                        'product-style': productstyle,
                        blur: requiredDomain == false,
                    }"
                    v-if="price"
                >
                    {{ price }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Cards',
    props: {
        img: String,
        cardname: String,
        price: String,
        unavailable: Boolean,
        available: Boolean,
        threeDavailable: Boolean,
        productstyle: Boolean,
        quantity: Number,
        forServices: Boolean,
        requiredDomain: Boolean,
    },
    data() {
        return {}
    },
}
</script>

<style lang="scss" scoped>
@import './Cards.scss';
</style>
