<template>
    <div class="style">
        <img
            class="style-image"
            :src="require(`@/assets/images/${this.img}`)"
            alt="Banner"
        />
        <div
            :class="{
                'banner-center': centerBanner,
                'banner-left': leftBanner,
                'banner-right': rightBanner,
            }"
        >
            <div class="style-details">
                <h1>{{ heading }}</h1>
                <Button
                    v-if="isSQ == true && isPQ == true"
                    class="button"
                    primary
                    name="Explore Designs"
                    :handleClick="openMDI"
                />
                <Button
                    v-else-if="isSQ == true && isPQ == false"
                    class="button"
                    primary
                    name="Explore Designs"
                    :handleClick="openMDI"
                />
                <Button
                    v-else
                    class="button"
                    primary
                    name="Create Project"
                    :handleClick="openStyleQuiz"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Button from 'componentsv2/Button'

export default {
    name: 'StyleBox',
    props: {
        img: {
            type: String,
        },
        heading: {
            type: String,
        },
        data: {
            type: Array,
        },
        centerBanner: {
            type: Boolean,
        },
        leftBanner: {
            type: Boolean,
        },
        rightBanner: {
            type: Boolean,
        },
        isSQ: Boolean,
        isPQ: Boolean,
    },
    components: {
        Button,
    },
    data() {
        return {
            url:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_QUIZ_BASE_URL_B2B
                    : process.env.VUE_APP_QUIZ_BASE_URL,
            baseUrl:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_BASE_URL_B2B
                    : process.env.VUE_APP_BASE_URL,
        }
    },
    computed: {
        ...mapState({
            isSQ: state => state.AuthModule.isSQ,
            isPQ: state => state.AuthModule.isPQ,
        }),
    },
    methods: {
        ...mapActions({}),
        openStyleQuiz() {
            window.open(`${this.url}?startQuiz=true`, '_self')
        },
        openMDI() {
            window.open(`${this.baseUrl}mudi/designideas`, '_self')
        },
    },
}
</script>

<style lang="scss" scoped>
@import './StyleBox';
</style>
