import { render, staticRenderFns } from "./StyleBox.vue?vue&type=template&id=4d48ceea&scoped=true&"
import script from "./StyleBox.vue?vue&type=script&lang=js&"
export * from "./StyleBox.vue?vue&type=script&lang=js&"
import style0 from "./StyleBox.vue?vue&type=style&index=0&id=4d48ceea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d48ceea",
  null
  
)

export default component.exports